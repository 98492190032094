import type {CaipNetwork} from "@reown/appkit";
import {
  type ContractId,
  ContractInfo,
  type CustomCaipNetwork,
  DefineCaipNetworkInfo,
  DefineContractInfo,
  DefineEvmContractInfo,
  DefineHavahContractInfo,
  DefineSwapPair,
  type TokenPairs
} from "@resources/@types/common/type";



export const defineNetwork = (defineNetworkInfo: DefineCaipNetworkInfo) => {
  const {success, data, error} = DefineCaipNetworkInfo.safeParse(defineNetworkInfo)

  if (!success || !data || error) throw new Error("Invalid define contract info")

  return {
    id: `${data.chainNamespace}:${data.chainId}`,
    ...data,
  } as CustomCaipNetwork
}

export const defineContractInfo = (defineInfo: DefineEvmContractInfo | DefineHavahContractInfo) => {
  const {success, data, error} = DefineContractInfo.safeParse(defineInfo)

  if (!success || !data || error) throw new Error("Invalid define contract info")

  const chainHasTestnet = Object.hasOwn(data, "chainTestnet")

  const {contract, devContract, stageContract, ...rest} = data

  // @resources/config/chain 에서 설정된 네트워크 기준으로 변경됨
  // Dev or Stage
  // Testnet
  if (chainHasTestnet && !!data?.chainTestnet) {
    if (import.meta.env.VITE_ENV === "dev") {
      return {
        ...rest,
        contract: devContract,
      }
    } else if (import.meta.env.VITE_ENV === "stage") {
      return {
        ...rest,
        contract: stageContract,
      }
    } else {
      return {
        ...rest,
        contract: "",
      }
    }
  }

  // Mainnet
  return {
    ...rest,
    contract: contract,
  }
}


export const defineSwapPair = (definePairs: DefineSwapPair) => {
  const {
          data   : _parsedDefinePairs,
          success: _successDefinePairs,
          error  : _errorDefinePairs
        } = DefineSwapPair.safeParse(definePairs)

  if (!_parsedDefinePairs || !_successDefinePairs || _errorDefinePairs) {
    throw new Error(`Invalid define swap pairs : ${_errorDefinePairs}`)
  }


  const pairKeyList = Object.keys(_parsedDefinePairs) as ContractId[]

  return pairKeyList.reduce((acc, cur) => {
    if (_parsedDefinePairs[cur].length === 3 && _parsedDefinePairs[cur][2]!.indexPair) {
      acc["indexPair"] = [_parsedDefinePairs[cur][0], _parsedDefinePairs[cur][1][0]]
      acc[cur]         = [_parsedDefinePairs[cur][0], _parsedDefinePairs[cur][1]]
      return acc
    }

    acc[cur] = _parsedDefinePairs[cur] as [ContractInfo, ContractInfo[]]

    return acc
  }, {} as TokenPairs)
}
