import {ORIGIN_DECIMAL} from "@resources/@types/common/constant";
import type {
  AddressTypes,
  EvmAddress,
  HavahAddress
}                       from "@resources/@types/common/type";
import {
  DotAfterLastZeroRegExp,
  DotLastRegExp,
  FirstZeroRegExp,
}                       from "@utils/RegExp";
import {
  isEvmAddress,
  isIconEoaAddress
}                       from "@utils/Validator";
import dayjs            from "dayjs";
import {IconService}    from "icon-sdk-js";
import {
  hexToBigInt,
  isHex
}                       from "viem";



export const iconToHexNumber      = IconService.IconConverter.toHexNumber
export const iconToHex            = IconService.IconConverter.toHex
export const iconToBigNumber      = IconService.IconConverter.toBigNumber
export const iconToNumber         = IconService.IconConverter.toNumber
export const iconFromUtf8         = IconService.IconConverter.fromUtf8
export const iconToRawTransaction = IconService.IconConverter.toRawTransaction
export const iconToUtf8           = IconService.IconConverter.toUtf8
export const iconToBoolean        = IconService.IconConverter.toBoolean

export const iconAmountOf       = IconService.IconAmount.of
export const iconAmountUnit     = IconService.IconAmount.Unit
export const iconAmountToLoop   = IconService.IconAmount.toLoop
export const iconAmountFromLoop = IconService.IconAmount.fromLoop


export const splittingAddress = (address?: AddressTypes): undefined | AddressTypes => {
  if (!!address && (isEvmAddress(address)))
    return `${address.slice(0, 4)}...${address.slice(-4)}` as EvmAddress
  else if (!!address && (isIconEoaAddress(address)))
    return `${address.slice(0, 4)}...${address.slice(-4)}` as HavahAddress

  return;
}



export const convertToDate = (
  targetDate: string | number | dayjs.Dayjs | Date | null | undefined,
  format?: string,
): string => {
  return dayjs.tz(targetDate).format(format ??  "YYYY-MM-DD HH:mm:ss")
}

export const removeAmountUnnecessaryVal = (amount: string) => {
  if (!Number(amount)) return "";

  return amount
    // 숫자 뒤 단일 소수점 제거
    .replace(DotLastRegExp, '')
    // 소수점 뒤의 1-9 이후 불필요한 0 제거
    .replace(DotAfterLastZeroRegExp, '$1')
}


export const decimalToBigInt = (value: number | string): bigint => {
  const stringValue = value.toString();

  if (isHex(stringValue)) {
    return hexToBigInt(stringValue);

  } else {
    const [integerPart, fractionalPart = ''] = stringValue.split('.');
    const paddedFractionalPart         = fractionalPart.padEnd(ORIGIN_DECIMAL, '0');

    return BigInt(integerPart + paddedFractionalPart);
  }
}

export const formatToDecimalString = (value: bigint, decimal?: number): string => {
  const _isNegative = value < BigInt(0)
  const _absValue   = _isNegative ? -value : value;

  let _strValue    = _absValue.toString().padStart((ORIGIN_DECIMAL + 1), '0');
  let _integerPart = _strValue.slice(0, -(ORIGIN_DECIMAL)) || '0';


  const _fractionalPart         = _strValue.slice(-(ORIGIN_DECIMAL)).slice(0, decimal ?? ORIGIN_DECIMAL);
  const _replacedFractionalPart = _fractionalPart.replace(/0+$/, '');


  if (_replacedFractionalPart) {
    _integerPart += '.' + _replacedFractionalPart;
  }

  const _formatted = _integerPart.replace(FirstZeroRegExp, '');

  return _isNegative ? '-' + _formatted : _formatted;
}




