// import {
//   arbitrum,
//   // arbitrumSepolia,
//   // mainnet
// }                    from "wagmi/chains";
import {
  DefineCommonChainInfo,
  type DefineIndexSwapPair,
  EvmContractInfo,
} from "@resources/@types/common/type";
import {
  defineContractInfo,
  defineNetwork,
  defineSwapPair
} from "@resources/config/contracts/config";
import {
  arbitrum as wagmiArbitrum,
  arbitrumSepolia as wagmiArbitrumSepolia,
} from "wagmi/chains";
// import {
//   arbitrum,
// }                    from "@reown/appkit/networks";



// eip155:421614
// imageUrl: string;
// imageId: string;
//
const _arbitrumOne = defineNetwork({
  chainId       : wagmiArbitrum.id,
  chainNamespace: "eip155",
  name          : wagmiArbitrum.name,
  currency      : wagmiArbitrum.nativeCurrency.symbol,
  explorerUrl   : wagmiArbitrum.blockExplorers.default.url,
  rpcUrl        : wagmiArbitrum.rpcUrls.default.http[0],
  // imageUrl      : z.string().optional(),
  // imageId       : z.string().optional(),
})

const _arbitrumSepolia = defineNetwork({
  chainId       : wagmiArbitrumSepolia.id,
  chainNamespace: "eip155",
  name          : wagmiArbitrumSepolia.name,
  currency      : wagmiArbitrumSepolia.nativeCurrency.symbol,
  explorerUrl   : wagmiArbitrumSepolia.blockExplorers.default.url,
  rpcUrl        : wagmiArbitrumSepolia.rpcUrls.default.http[0],
})

export const arbitrumOne = import.meta.env.VITE_WEB3_NETWORK_TYPE !== "mainnet" ? wagmiArbitrumSepolia : wagmiArbitrum
// export const arbitrumOne: CustomCaipNetwork = {
//   ...(import.meta.env.VITE_WEB3_NETWORK_TYPE !== "mainnet" ? _arbitrumSepolia : _arbitrumOne),
//   imageUrl: getImageUrl("src/assets/images/logo/chain/arbitrum-one-logo.svg"),
// }

const defaultConfig: DefineCommonChainInfo = {
  chainId     : arbitrumOne.id,
  chainName   : arbitrumOne.name,
  chainTestnet: arbitrumOne.testnet,
  // chainTestnet: import.meta.env.VITE_WEB3_NETWORK_TYPE !== "mainnet"
}


/**
 @BRIDGE
 */
const arbBridge = defineContractInfo({
  ...defaultConfig,
  name         : "ARB Bridge",
  id           : "arbitrumOne-arb-bridge",
  symbol       : "ARB",
  contract     : "0x764C1C968DEaA26FB72730Ed12e29921f7889e2E",
  devContract  : "0x7C9d569aB264472ab3327d3e79Bd766A0040Cf80",
  stageContract: "0x5f3eb8ab0C6CE3402517bc580D266d4C884968Fc",
}) as EvmContractInfo

const perBridge = defineContractInfo({
  ...defaultConfig,
  name         : "PER Bridge",
  id           : "arbitrumOne-per-bridge",
  symbol       : "PER",
  contract     : "0x483610f71bf161D974c6E6a40228f8640AD06CEd",
  devContract  : "0xF56aca1b3fbC5F39a60996662e3b5943a2D479C8",
  stageContract: "0x074d0AdE149c0636aac3b070866D291da75b7c34",
}) as EvmContractInfo

const xperBridge = defineContractInfo({
  ...defaultConfig,
  name         : "XPER Bridge",
  id           : "arbitrumOne-xper-bridge",
  symbol       : "XPER",
  contract     : "0xfb65efeaC3ce7bf5CB47aBbAF8e9201E22a5dc65",
  devContract  : "0x7f7511AcB0F884F7230f9e185e5981c770986D07",
  stageContract: "0xbc23f4F839FE9C4A7FD2441b74c2d7129407190A",
}) as EvmContractInfo


const perplayNftBridge = defineContractInfo({
  ...defaultConfig,
  name         : "XPER Bridge",
  id           : "arbitrumOne-xper-bridge",
  symbol       : "XPER",
  contract     : "0xdB0B59214Ec25fFE5892cBDE1cB40E959dD6a29f",
  devContract  : "0x25F10B3917aD87b16fA55A05e62748018bC07739",
  stageContract: "0xe45b2Db9e99f34EaB966e2e65d34045e726C02D5",
}) as EvmContractInfo


export const arbitrumOneBridges = {
  ARB_BRIDGE        : arbBridge,
  PER_BRIDGE        : perBridge,
  XPER_BRIDGE       : xperBridge,
  PERPLAY_NFT_BRIDGE: perplayNftBridge,
} as const


/**
 @TOKEN
 */
const arbToken = defineContractInfo({
  ...defaultConfig,
  name         : "ARB",
  id           : "arbitrumOne-arb-token",
  symbol       : "ARB",
  contract     : "0x912CE59144191C1204E64559FE8253a0e49E6548",
  devContract  : "0xE3AeEb6172327BEcFbd30d797cEA5B954b24Adf4",
  stageContract: "0xE3AeEb6172327BEcFbd30d797cEA5B954b24Adf4",
}) as EvmContractInfo

const perToken = defineContractInfo({
  ...defaultConfig,
  name         : "PER",
  id           : "arbitrumOne-per-token",
  symbol       : "PER",
  contract     : "0x3f7f123BaB1fD545aa201194f628E933413fFFD7",
  devContract  : "0xeE5210e0B55352B0a8956448Bc60Eb3C386Da052",
  stageContract: "0xeE5210e0B55352B0a8956448Bc60Eb3C386Da052",
}) as EvmContractInfo

const xperToken = defineContractInfo({
  ...defaultConfig,
  name         : "XPER",
  id           : "arbitrumOne-xper-token",
  symbol       : "XPER",
  contract     : "0xd420583dd2f1e53c41c3433be72b30202f5e51a3",
  devContract  : "0x5dE38589a58e5244a41a97550EC8E81aDB8fFEB7",
  stageContract: "0x5dE38589a58e5244a41a97550EC8E81aDB8fFEB7",
}) as EvmContractInfo


export const arbitrumOneTokens = {
  ARB_TOKEN : arbToken,
  PER_TOKEN : perToken,
  XPER_TOKEN: xperToken,
} as const


/** @NFT */
const perplayNft = defineContractInfo({
  ...defaultConfig,
  id           : "arbitrumOne-perplay-nft",
  name         : "PERPLAY",
  symbol       : "VEHICLE",
  contract     : "0xDBF4F55ea7375229572f12a7eAF8D4865c15F49d",
  devContract  : "0x4c99346f3E4d25D73DE0B585816F23592b79655D",
  stageContract: "0xECb3e55D4aFe39EfE5fC066ebF32a3E88bFee8Ac",
}) as EvmContractInfo

export const arbitrumOneNftContract = {
  PERPLAY: perplayNft,
} as const


/** @SWAP */
export const arbitrumOneSwapPair = defineSwapPair({
  [arbToken.id] : [arbToken, [perToken, xperToken], {indexPair: true}] as DefineIndexSwapPair,
  [perToken.id] : [perToken, [arbToken]],
  [xperToken.id]: [xperToken, [arbToken]],
})

